var gaEvent = (function () {
  var self = {};

  var runEvent = function (event, element) {
    var dataAttrs = element.dataset;

    var eventData = {
      category: dataAttrs['eventCategory'],
      action: dataAttrs['eventAction'],
      label: dataAttrs['eventLabel']
    };

    if (typeof ga === 'undefined') {
      console.dir(eventData);
    }

    // Analytics
    if (eventData.category && eventData.action && typeof ga === 'function') {
      gtag('event', eventData.action, {
        'event_category': eventData.category,
        'event_label': eventData.label,
        'event_callback': function() {
          console.dir(eventData);
        }
      });
    }
  }

  var matchesCheck = function () {
    // IE support: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
    if (!Element.prototype.matches) {
      Element.prototype.matches = Element.prototype.msMatchesSelector;
    }
  }

  var addEvent = function (parent, evt, selector, handler) {
    parent.addEventListener(evt, function (event) {
      if (event.target.matches(selector + ', ' + selector + ' *')) {
        handler.apply(event.target.closest(selector), arguments);
      }
    }, false);
  }

  self.init = function (selector) {
    matchesCheck();
    addEvent(document, 'click', selector, function (e) {
      runEvent(e, this);
    });
  }

  return self;
}());

gaEvent.init('.ga-event');
