import $ from 'jquery';

function breadcrumbNavigation() {
  if ($('.breadcrumb-links-list').length) {
    $('.breadcrumb-links-list__item:not(.breadcrumb-links-list__item--active)').remove();
    $('.breadcrumb-links-list__link--active').last().addClass("breadcrumb-links-list__link--last");
  }
}

$(function() {
  breadcrumbNavigation();
});
