import $ from 'jquery';
import 'slick-carousel';

function turbolinksCheck() {
  return (typeof Turbolinks == 'undefined') ? 'DOMContentLoaded' : 'turbolinks:load';
}

let initializer = turbolinksCheck();

function getSlickyWithIt() {
  if($('.js--home-banners').length) {
    $('.js--home-banners').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      fade: true,
      autoplaySpeed: 4000
    });
  }

  if($('.home-content__testimonial-slick').length) {
    $('.home-content__testimonial-slick').slick({
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'home-information-testimonials-dots'
    });
  }

  /*
  if($('.page-footer-testimonials__slick').length) {
    $('.page-footer-testimonials__slick').slick({
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'page-footer-testimonials-dots'
    });
  }
  */

  if ($('.page-aside-testimonials__slick').length) {
    $('.page-aside-testimonials__slick').slick({
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'page-aside-testimonials-dots'
    });
  }

  if ($('.page-footer-additional-content-course-cta__testimonial-slick').length) {
    $('.page-footer-additional-content-course-cta__testimonial-slick').slick({
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      dotsClass: 'page-footer-additional-content-course-cta-testimonials-dots'
    });
  }
}

document.addEventListener(
  initializer,
  getSlickyWithIt
);
