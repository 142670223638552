import $ from 'jquery';

function toggleFields() {
  $('.js--toggle-field').each(function () {
    var $field = $(this);
    var $element = $($(this).data('toggle-field'));

    if ($element.length == 0) {
      return;
    }

    if ($field.is(':checked')) {
      $element.show();
    } else {
      $element.hide();
    }

    if($('.referral-screening-form__output--error:visible').length) {
      $('.referral-screening-form__output--success').hide();
      $('.referral-screening-form__submit').attr('disabled', true);

      var index = $('.referral-screening-form__output--error:visible:first').parent('.referral-screening-form__field').index();
      $('.referral-screening-form__field').slice(index).hide();
      $('.referral-screening-form__field').slice(index)
                                          .find('.js--toggle-field:checked')
                                          .toggleClass('js--toggle-field js--toggle-field-checked')
                                          .prop('checked', false)
                                          .addClass('js--toggle-field')
                                          .removeClass('js--toggle-field-checked');

    } else {
      $('.referral-screening-form__submit').removeAttr('disabled');
    }
  });
}

$(function() {
  if ($('.js--toggle-field:checked').length) {
    $('.referral-screening-form__field').show();
  }

  toggleFields();
})

$(document).on('change', '.js--toggle-field', function() {
  toggleFields();

  var $element = $($(this).data('toggle-field'));

  if ($element.length == 0) {
    $('.referral-screening-form__field:hidden:first').show();
    return;
  }
});
